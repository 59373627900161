/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react'
import { Body2, ButtonText } from 'components/elements/Text'
import { useState } from 'react'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { Grade } from 'interfaces/Grade'
import GenericFormElement from '../GenericFormElement'
import { SchoolType } from 'interfaces/SchoolType'
import { Region } from 'interfaces/Region'
import GradeSelectionSchoolTypes from './GradeSelectionSchoolTypes'
import GradeSelectionDialog from './GradeSelectionDialog'
import GradeSelectionGrades from './GradeSelectionGrades'
import GradeSelectionRegion from './GradeSelectionRegion'
import Button from 'components/elements/Button'
import Stack from 'components/elements/Stack'
import { useToggleState } from 'utils/hooks/useToggleState'
import { Country } from 'interfaces/Country'
import GradeSelectionCountry from './GradeSelectionCountry'
import { returnGradeLabel } from 'lib/features/grades'

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
`

const Separator = styled.div`
    height: 1px;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.accentBlue};
`

const Hidden = styled.div`
    display: none;
`

const SelectorContainer = styled.div`
    min-height: auto;
    height: 100%;
`

const RegionTextContainer = styled.div`
    display: flex;
    gap: 5px;
    .change-region {
        cursor: pointer;
    }
`

interface Props {
    name: string
    label: string
    defaultSchoolTypes?: SchoolType[]
    defaultGrades?: Grade[]
    onConfirm?: (selectedSchoolTypes: SchoolType[], selectedGrades: Grade[]) => void
    limitToOneSelectedItem?: boolean
    enableMultipleRegionsAndSchooltypes?: boolean
}

const GradeSelection = (props: Props) => {
    // Others
    const [showDialog, toggleShowDialog] = useToggleState(false)

    // Selected values
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

    const [selectedRegion, setSelectedRegion] = useState<Region | null>(null)

    const [selectedSchoolTypes, setSelectedSchoolTypes] = useState<SchoolType[] | undefined>(props.defaultSchoolTypes)

    const [selectedGrades, setSelectedGrades] = useState<Grade[]>(props.defaultGrades ?? [])
    const selectedGradeIds = selectedGrades.sort((a, b) => a.level - b.level).map((g) => g.id)

    useEffect(() => {
        if (!props.defaultGrades) return

        setSelectedGrades(props.defaultGrades)
    }, [props.defaultGrades])

    //COUNTRY
    const handleCountrySelect = (country: Country) => setSelectedCountry(country)

    const goBackToCountrySelect = () => {
        setSelectedCountry(null)
        setSelectedSchoolTypes([])
        setSelectedRegion(null)
    }

    //REGION
    const showRegionsOptions = !selectedRegion?.isDefault

    const goBackToRegionSelect = () => {
        !props.enableMultipleRegionsAndSchooltypes && setSelectedGrades([])
        setSelectedSchoolTypes([])
        setSelectedRegion(null)
    }

    const handleRegionSelect = (region: Region) => setSelectedRegion(region)

    const changeDefaultSchoolTypes = (schoolTypes: SchoolType[]) => setSelectedSchoolTypes(schoolTypes)

    //GRADE
    const toggleGrade = (grade: Grade | null) => {
        if (!grade) {
            setSelectedGrades([])
            return
        }

        if (props.limitToOneSelectedItem) {
            setSelectedGrades([grade])
            return
        }

        const isAlreadySelected = selectedGradeIds.includes(grade.id)

        if (isAlreadySelected) {
            setSelectedGrades(selectedGrades.filter((g) => g.id !== grade.id))
        } else {
            setSelectedGrades([...selectedGrades, grade])
        }
    }

    const onCloseDialog = () => {
        props.onConfirm && props.onConfirm(selectedSchoolTypes ?? [], selectedGrades)
        toggleShowDialog()
    }

    const selectedPreview = selectedGrades?.length
        ? selectedGrades.map((g) => g.longName).join(', ')
        : props.defaultGrades?.map((g) => returnGradeLabel(g)).join(', ') ?? ''

    return (
        <>
            <GradeSelectionDialog open={showDialog} handleClose={onCloseDialog}>
                <SelectorContainer>
                    {!selectedCountry ? (
                        <GradeSelectionCountry handleCountrySelect={handleCountrySelect} />
                    ) : (
                        <>
                            <RegionTextContainer>
                                <Body2 color={theme.colors.body2Black}>
                                    {`Selected country: ${selectedCountry.localizedName}`}
                                </Body2>
                                <Body2
                                    color={theme.colors.knowunityBlue}
                                    onClick={goBackToCountrySelect}
                                    fontWeigth={700}
                                    className="change-region"
                                >
                                    Change Country
                                </Body2>
                            </RegionTextContainer>
                            {showRegionsOptions && !selectedRegion ? (
                                <GradeSelectionRegion
                                    handleRegionSelect={handleRegionSelect}
                                    selectedCountry={selectedCountry}
                                />
                            ) : (
                                <Stack>
                                    {showRegionsOptions && selectedRegion ? (
                                        <RegionTextContainer>
                                            <Body2 color={theme.colors.body2Black}>
                                                {`Selected region name: ${selectedRegion.regionName}`}
                                            </Body2>
                                            <Body2
                                                color={theme.colors.knowunityBlue}
                                                onClick={goBackToRegionSelect}
                                                fontWeigth={700}
                                                className="change-region"
                                            >
                                                Change Region
                                            </Body2>
                                        </RegionTextContainer>
                                    ) : null}
                                    <GradeSelectionSchoolTypes
                                        changeDefaultSchoolTypes={changeDefaultSchoolTypes}
                                        selectedRegion={selectedRegion}
                                        initialSchoolTypes={selectedSchoolTypes}
                                        onUpdate={setSelectedSchoolTypes}
                                        limitToOneSelectedItem={props.limitToOneSelectedItem}
                                    />
                                    <Separator />
                                    <GradeSelectionGrades
                                        selectedGrades={selectedGrades}
                                        selectedSchoolTypes={selectedSchoolTypes}
                                        toggleGrade={toggleGrade}
                                        limitToOneSelectedItem={props.limitToOneSelectedItem}
                                    />
                                    <ButtonsWrapper>
                                        <Button onClick={onCloseDialog} fullWidth hoverColor={theme.colors.knowunityBlue}>
                                            <ButtonText color={theme.colors.white}>Confirm</ButtonText>
                                        </Button>
                                    </ButtonsWrapper>
                                </Stack>
                            )}
                        </>
                    )}
                </SelectorContainer>
            </GradeSelectionDialog>

            <Hidden>
                <GenericFormElement
                    name="schoolTypes"
                    onClick={toggleShowDialog}
                    label=""
                    placeholder=""
                    value={selectedSchoolTypes}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onUpdate={() => {}}
                    selectedPreview=""
                    noMargin
                />
            </Hidden>
            <GenericFormElement
                data-id="grades"
                name={props.name}
                onClick={toggleShowDialog}
                label={props.label}
                placeholder={props.label}
                value={selectedGrades}
                selectedPreview={selectedPreview}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onUpdate={() => {}}
                noMargin
            />
        </>
    )
}

export default GradeSelection
