import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, Checkbox, FormSelect } from 'components/inputs/Input'
import FeedbackDialogService from './FeedbackDialogService'
import {
    FeedbackDialog,
    FeedbackDialogAnswerOptionRequest,
    FeedbackDialogCreateRequest,
    FeedbackInputType,
} from 'interfaces/FeedbackDialog'
import routes from 'lib/constants/routes'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import Stack from 'components/elements/Stack'
import { Body1, Body2, Headline5, InlineText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Box from 'components/elements/Box'
import { SelectOption } from 'components/inputs/Input'
import InputElement from 'components/inputs/InputElement'

interface FeedbackFormData {
    actionLink: string
    actionsUntilTriggered: string
    identifier: string
    isActive: boolean
    isMultipleChoice: boolean
    title: string
    percentage: string
    inputType: SelectOption<FeedbackInputType>
}

interface Props {
    dialogUuid: string
    isNew: boolean
    feedbackDialog: FeedbackDialog | null
    updateFeedbackDialog: (feedback: FeedbackDialog) => void
}

const loadingAreas = {
    save: 'saveFeedback',
}

const FeedbackDialogForm = ({ dialogUuid, isNew, feedbackDialog, updateFeedbackDialog }: Props) => {
    const [showFeedbackExplanationDialog, toggleFeedbackExplanationDialog] = useToggleState(isNew)
    const {
        userTargetingId: targeting,
        renderTargetingModal,
        renderTargetingButton,
    } = useUserTargetingForm(feedbackDialog?.userTargetingId, isNew)
    const createFeedbackDialogFormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const [answers, setAnswers] = useState<FeedbackDialogAnswerOptionRequest[]>([])

    useEffect(() => {
        if (!feedbackDialog) return
        setAnswers(feedbackDialog.answerOptions)
    }, [feedbackDialog, feedbackDialog?.answerOptions, setAnswers])

    const initialData: FeedbackFormData | undefined = feedbackDialog
        ? {
              actionLink: feedbackDialog.actionLink,
              actionsUntilTriggered: feedbackDialog.actionsUntilTriggered.toString(),
              identifier: feedbackDialog.identifier,
              isActive: feedbackDialog.isActive,
              isMultipleChoice: feedbackDialog.isMultipleChoice,
              title: feedbackDialog.title,
              percentage: feedbackDialog.percentage.toString(),
              inputType: {
                  data: feedbackDialog.inputType,
                  identifier: feedbackDialog.inputType,
              },
          }
        : undefined

    const addAnswerText = () =>
        setAnswers((prevState) =>
            prevState ? [...prevState, { answerText: '', actionLink: '' }] : [{ answerText: '', actionLink: '' }]
        )

    const inputTypeOptions = Object.keys(FeedbackInputType).map((type) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: FeedbackInputType[type],
        // eslint-disable-next-line
        // @ts-ignore
        identifier: FeedbackInputType[type],
        // eslint-disable-next-line
        // @ts-ignore
        data: FeedbackInputType[type],
    }))

    const handleCreateFeedbackSubmit: SubmitHandler<FeedbackFormData> = (data) => {
        createFeedbackDialogFormRef.current!.setErrors({})

        if (!targeting) {
            toast.warn('You need to add User Targeting!')
            return
        }

        const percentage = parseInt(data.percentage) || 0

        if (percentage < 1 || percentage > 100) {
            toast.warn('Please enter correct data for percentage (1-100)')
            return
        }

        if (data.inputType.data === FeedbackInputType.Link && !data.actionLink) {
            toast.warn('You need to provide action link if the to input type is LINK!')
            return
        }

        if (data.inputType.data === FeedbackInputType.Answer && !answers?.length) {
            toast.warn('You need to provide answer options if the input type is ANSWER!')
            return
        }

        const body: FeedbackDialogCreateRequest = {
            identifier: data.identifier,
            isActive: data.isActive !== undefined ? data.isActive : true,
            title: data.title,
            userTargetingId: targeting,
            actionLink: data.actionLink,
            actionsUntilTriggered: parseInt(data.actionsUntilTriggered) || 0,
            answerOptions: answers,
            percentage: percentage,
            inputType: data.inputType.data,
            isMultipleChoice: data.isMultipleChoice,
        }

        const handleFunction = isNew ? FeedbackDialogService.create(body) : FeedbackDialogService.update(dialogUuid, body)

        trackPromise(
            handleFunction
                .then((feedbackDialog) => {
                    toast.success(`Feedback dialog has been ${isNew ? 'created' : 'updated'}`)
                    isNew && history.push(routes.feedbackDialogRoute(feedbackDialog.uuid))
                    updateFeedbackDialog(feedbackDialog)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, createFeedbackDialogFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding feedback dialog to the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const handleAnswerTextUpdate = (index: number, value: string) => {
        setAnswers((prevAnswers) => {
            prevAnswers[index].answerText = value
            return prevAnswers.map((answer) => answer)
        })
    }

    const handleAnswerActionLinkUpdate = (index: number, value: string) => {
        setAnswers((prevAnswers) => {
            prevAnswers[index].actionLink = value
            return prevAnswers.map((answer) => answer)
        })
    }

    return (
        <>
            <Form ref={createFeedbackDialogFormRef} onSubmit={handleCreateFeedbackSubmit} initialData={initialData}>
                <Input name="title" placeholder="Title" />
                <FormSelect name="inputType" placeholder="Input Type" options={inputTypeOptions} />
                <Input name="actionLink" placeholder="Action link" />
                <Input name="actionsUntilTriggered" placeholder="Actions until triggered" type="number" />
                {renderTargetingButton()}
                <Input name="identifier" placeholder="Identifier" />
                <Input name="percentage" placeholder="Percentage" type="number" />
                {!isNew && <Checkbox name="isActive" placeholder="Is active" />}
                <Checkbox name="isMultipleChoice" placeholder="Is Multiple Choice" />
                {answers?.length
                    ? answers?.map((answer, index) => (
                          <Box key={`answer-box-${index + 1}`}>
                              <Stack gutter={0}>
                                  <Body2 color={theme.colors.white} marginBottom={10}>{`Answer ${index + 1}`}</Body2>
                                  <InputElement
                                      label="Answer text"
                                      noMargin
                                      onInput={(value) => handleAnswerTextUpdate(index, value)}
                                      defaultValue={answer.answerText}
                                  />
                                  <InputElement
                                      label="Answer action link"
                                      noMargin
                                      onInput={(value) => handleAnswerActionLinkUpdate(index, value)}
                                      defaultValue={answer.actionLink || ''}
                                  />
                              </Stack>
                          </Box>
                      ))
                    : null}
                <Button fullWidth icon={<AddIcon />} loadingArea="none" type="button" onClick={addAnswerText}>
                    Add more answer texts
                </Button>
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {`${isNew ? 'Create' : 'Save'} Feedback Dialog Form`}
                </Button>
            </Form>
            {renderTargetingModal()}
            <Modal
                title="Feedback Dialog Instruction"
                show={showFeedbackExplanationDialog}
                onClose={toggleFeedbackExplanationDialog}
            >
                <Stack>
                    <Headline5 color={theme.colors.white} textAlign="center">
                        How to create a valid in-app feedback dialogue?
                    </Headline5>

                    <ul>
                        <li>
                            <Body1 color={theme.colors.white} fontWeigth={400}>
                                Ask simple <InlineText>WHAT/HOW</InlineText> questions instead of{' '}
                                <InlineText>WHY</InlineText> questions. (e.g. what do you currently work on?)
                            </Body1>
                        </li>
                        <li>
                            <Body1 color={theme.colors.white} fontWeigth={400}>
                                “Select answer options” work best for this kind of feedback -{' '}
                                <InlineText>Please try to limit it to max 5 answer options.</InlineText>
                            </Body1>
                        </li>
                        <li>
                            <Body1 color={theme.colors.white} fontWeigth={400}>
                                Ask <InlineText>one simple question at a time!</InlineText> Make sure the answer options are{' '}
                                <InlineText>mutually exclusive</InlineText> if users can just select one.
                            </Body1>
                        </li>
                        <li>
                            <Body1 color={theme.colors.white} fontWeigth={400}>
                                You need to ask for a why? Work with statement-agreement! (E.g. I find Knowunity easy to use.
                                Agree - Disagree)
                            </Body1>
                        </li>
                    </ul>
                </Stack>
            </Modal>
        </>
    )
}

export default FeedbackDialogForm
