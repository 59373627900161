import React, { useCallback, useEffect, useRef, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import PayoutRequestService from 'services/PayoutRequestService'
import { PayoutDestination, PayoutRejectionReason, PayoutRequest as PayoutRequestInterface } from 'interfaces/PayoutRequest'
import { formatAmount, formatCamelCase, formatDateTime, getNextRedirectUuid } from 'utils'
import routes from 'lib/constants/routes'
import Button from 'components/elements/Button'
import CopyIcon from 'components/elements/CopyIcon'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import styled from 'styled-components'
import GoBackButton from 'components/elements/GoBackButton'
import { Currency } from 'interfaces/Transaction'
import theme from 'lib/constants/theme'
import { sevDeskPayoutUrl } from 'lib/features/payouts'
import FraudDetectionResult from './FraudDetectionResult'
import InformationCard from 'components/features/InformationCard'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const InvoiceViewer = styled.iframe`
    @media (max-width: 750px) {
        height: 600px;
    }
`

const RefusalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    button {
        position: relative;
        top: 5px;
    }
`

const loadingAreas = {
    container: 'payoutRequestContainer',
    maxPayout: 'maxPayoutRequest',
}

const PayoutRequest = (props: Props) => {
    const [paymentRefuseReason, setPaymentRefuseReason] = useState<PayoutRejectionReason>(
        PayoutRejectionReason.WrongPayoutDetails
    )
    const [payoutRequest, setPayoutRequest] = useState<PayoutRequestInterface>()
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const [id, setId] = useState(props.match.params.id)
    const iframeRef = useRef<HTMLIFrameElement | null>(null)
    const nextRedirect = getNextRedirectUuid(id)
    const payoutEverything = payoutRequest?.amount === null

    const paymentRefusalReasons = Object.keys(PayoutRejectionReason).map((typeKey) => ({
        label: formatCamelCase(typeKey),
        // eslint-disable-next-line
        // @ts-ignore
        data: PayoutRejectionReason[typeKey],
    }))

    const updatePaymentRefusalReason = (reason: PayoutRejectionReason) => setPaymentRefuseReason(reason)

    useEffect(() => {
        if (!iframeLoaded && payoutRequest?.filePath) {
            const interval = setInterval(() => {
                if (iframeRef.current) {
                    iframeRef.current.src = `https://docs.google.com/viewerng/viewer?url=${payoutRequest.filePath}&embedded=true`
                }
            }, 1000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [iframeLoaded, iframeRef, payoutRequest?.filePath])

    const fetchData = useCallback(() => {
        if (!id) return

        trackPromise(
            PayoutRequestService.get(id)
                .then((payout) => {
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    setPayoutRequest(undefined)
                    error.handleUnknown('An error occurred while getting payout request.')
                }),
            loadingAreas.container
        )
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const goToNextReviewKnow = () => {
        if (nextRedirect === undefined) {
            return false
        } else if (nextRedirect === null) {
            props.history.push(routes.reportsRoute)
            return true
        }

        props.history.push(routes.payoutRequestRoute(nextRedirect.nextUuid, { redirectIds: nextRedirect.remainingUuids }))
        setId(nextRedirect.nextUuid)

        return true
    }

    const closePayoutRequest = useCallback(() => {
        if (!id) return

        if (
            !window.confirm(
                `Are you sure you want to mark the payout request as done? This reduces the balance of the user and sends an email with the invoice to the user.`
            )
        )
            return
        trackPromise(
            PayoutRequestService.close(id)
                .then((payout) => {
                    toast.success('Payout request was marked as done')
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Payout request with this Uuid doesn't exist!")
                            return
                        } else if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This payout request was already marked as done!')
                            return
                        } else {
                            error.handleUnknown('An error occurred while marking the payout request as done.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [id])

    const setRequestAmountToKnowerBalance = useCallback(() => {
        if (!payoutEverything || !id) return

        trackPromise(
            PayoutRequestService.setPayoutAmountToKnowerBalance(id)
                .then((payout) => {
                    toast.success('Payout request amount was set to Knower balance')
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Payout request with this Uuid doesn't exist!")
                            return
                        } else {
                            error.handleUnknown('An error occurred while setting payout amount to knower balance.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [id, payoutEverything])

    const createPayoutVoucher = useCallback(() => {
        if (!id) return

        trackPromise(
            PayoutRequestService.createPayoutVoucher(id)
                .then((payout) => {
                    toast.success('SevDesk Voucher was created!')
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Payout request with this Uuid doesn't exist!")
                            return
                        } else if (error.type === ErrorTypes.Duplicate) {
                            toast.error(`A voucher was already created for this Payout request!`)
                            return
                        } else {
                            error.handleUnknown('An error occurred while creating SevDesk Voucher.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [id])

    const createPaypalPayment = useCallback(() => {
        if (!id) return

        trackPromise(
            PayoutRequestService.closePaypalPaymentAndCreatePayout(id)
                .then((payout) => {
                    toast.success('Paypal payment was created!')
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Payout request with this Uuid doesn't exist!")
                            return
                        } else if (error.type === ErrorTypes.Duplicate) {
                            toast.error(`A payment was already created for this payout request!`)
                            return
                        } else {
                            error.handleUnknown('An error occurred while creating Paypal Payment.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [id])

    const refusePayoutRequest = useCallback(() => {
        if (!id) return

        if (!paymentRefuseReason) {
            toast.warning('Please select payment refusal reason')
            return
        }

        if (
            !window.confirm(
                `Are you sure you want to refuse this payout request? This way the payout will be cancelled and user will not receive the funds.`
            )
        )
            return
        trackPromise(
            PayoutRequestService.refuse(id, paymentRefuseReason)
                .then((payout) => {
                    toast.success('Payout request was refused')
                    setPayoutRequest(payout)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Payout request with this Uuid doesn't exist!")
                            return
                        } else if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This payout request was already refused before!')
                            return
                        } else {
                            error.handleUnknown('An error occurred while refusing payout request.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [id, paymentRefuseReason])

    const showCreateSevDeskVoucherButton =
        payoutRequest?.currency === Currency.EUR && payoutRequest?.destination === PayoutDestination.Transaction

    const showPaypalButton = payoutRequest?.destination === PayoutDestination.PayPal

    return (
        <section>
            <Meta title="Payout Request" />
            <h2>Payout Request</h2>
            <GoBackButton route={routes.payoutRequestsRoute} routeName="Payout Request" />
            <Box>
                <Stack>
                    <h3>Information</h3>
                    <LoadingArea area={loadingAreas.container}>
                        {payoutRequest ? (
                            <>
                                <p>
                                    <strong>Uuid:</strong>&nbsp;
                                    {payoutRequest.uuid}
                                    <CopyIcon text={payoutRequest.uuid} />
                                </p>
                                <p>
                                    <strong>Knower UUID:</strong>&nbsp;
                                    {payoutRequest.knowerUUID ? (
                                        <>
                                            <Link to={routes.knowerRoute(payoutRequest.knowerUUID)}>
                                                {payoutRequest.knowerUUID}
                                            </Link>
                                            <CopyIcon text={payoutRequest.knowerUUID} />
                                        </>
                                    ) : (
                                        'None'
                                    )}
                                </p>
                                <p>
                                    <strong>Closed by:</strong>&nbsp;
                                    {payoutRequest.closedByUserUuid ? (
                                        <>
                                            <Link to={routes.userRoute(payoutRequest.closedByUserUuid)}>
                                                {payoutRequest.closedByUserUuid}
                                            </Link>
                                            <CopyIcon text={payoutRequest.closedByUserUuid} />
                                        </>
                                    ) : (
                                        'No one'
                                    )}
                                </p>
                                <p>
                                    <strong>Status:</strong>&nbsp;
                                    <strong>{payoutRequest.status}</strong>
                                </p>
                                <p>
                                    <strong>Invoice Id:</strong>&nbsp;
                                    {payoutRequest.invoiceID}
                                </p>
                                {payoutRequest?.sevDeskVoucherId ? (
                                    <p>
                                        <strong>SevDesk Voucher:</strong>&nbsp;
                                        <a href={sevDeskPayoutUrl(payoutRequest.sevDeskVoucherId)}>
                                            {sevDeskPayoutUrl(payoutRequest.sevDeskVoucherId)}
                                        </a>
                                    </p>
                                ) : null}
                                <p>
                                    <strong>Full name:</strong>&nbsp;
                                    {payoutRequest.fullName}
                                </p>
                                <p>
                                    <strong>Amount:</strong>&nbsp;
                                    {payoutEverything
                                        ? 'Eveything'
                                        : formatAmount(payoutRequest.amount, payoutRequest.currency)}
                                </p>
                                <p>
                                    <strong>Address:</strong>&nbsp;
                                    {payoutRequest.address}
                                </p>
                                <p>
                                    <strong>Ip Address Country:</strong>&nbsp;
                                    {payoutRequest.ipAddressCountry ?? 'N/A'}
                                </p>
                                <p>
                                    <strong>Created On:</strong>&nbsp;
                                    {formatDateTime(payoutRequest.createdOn)}
                                </p>
                                <p>
                                    <strong>Closed On:</strong>&nbsp;
                                    {payoutRequest.closedOn && !payoutRequest?.isOpen
                                        ? formatDateTime(payoutRequest.closedOn)
                                        : 'Not yet'}
                                </p>
                                <p>
                                    <strong>Destination:</strong>&nbsp;
                                    {payoutRequest.destination}
                                </p>
                                {payoutRequest?.payPalTransactionStatus ? (
                                    <InformationCard title={'Paypal Transaction'}>
                                        <Stack>
                                            <p>
                                                <strong>Status:</strong>&nbsp;
                                                {payoutRequest.payPalTransactionStatus}
                                            </p>
                                            {payoutRequest?.payPalTransactionUrl ? (
                                                <p>
                                                    <strong>Url:</strong>&nbsp;
                                                    <a href={payoutRequest.payPalTransactionUrl}>
                                                        {payoutRequest.payPalTransactionUrl}
                                                    </a>
                                                </p>
                                            ) : null}
                                            {payoutRequest?.payPalTransactionId ? (
                                                <p>
                                                    <strong>Transaction id:</strong>&nbsp;
                                                    {payoutRequest.destination}
                                                </p>
                                            ) : null}
                                            {payoutRequest?.payPalBatchId ? (
                                                <p>
                                                    <strong>Batch id:</strong>&nbsp;
                                                    {payoutRequest.payPalBatchId}
                                                </p>
                                            ) : null}
                                        </Stack>
                                    </InformationCard>
                                ) : null}
                                <p>
                                    <strong>Iban:</strong>&nbsp;
                                    {payoutRequest.iban ?? ''}
                                    {payoutRequest.iban && <CopyIcon text={payoutRequest.iban} />}
                                </p>
                                <p>
                                    <strong>Tax number:</strong>&nbsp;
                                    {payoutRequest.taxNumber ?? ''}
                                </p>
                                {payoutRequest?.phoneNumber ? (
                                    <p>
                                        <strong>Phone Number:</strong>&nbsp;
                                        {payoutRequest.phoneNumber}
                                    </p>
                                ) : null}
                                {payoutRequest?.bankAccountNumber ? (
                                    <p>
                                        <strong>Bank account number:</strong>&nbsp;
                                        {payoutRequest.bankAccountNumber}
                                    </p>
                                ) : null}
                                {payoutRequest?.bankAccountType ? (
                                    <p>
                                        <strong>Bank account type:</strong>&nbsp;
                                        {payoutRequest.bankAccountType}
                                    </p>
                                ) : null}
                                {payoutRequest?.firstName ? (
                                    <p>
                                        <strong>First name:</strong>&nbsp;
                                        {payoutRequest.firstName}
                                    </p>
                                ) : null}
                                {payoutRequest?.lastName ? (
                                    <p>
                                        <strong>Last name:</strong>&nbsp;
                                        {payoutRequest.lastName}
                                    </p>
                                ) : null}
                                {payoutRequest?.codeBank ? (
                                    <p>
                                        <strong>Bank code:</strong>&nbsp;
                                        {payoutRequest.codeBank}
                                    </p>
                                ) : null}
                                {payoutRequest?.documentNumber ? (
                                    <p>
                                        <strong>Document number:</strong>&nbsp;
                                        {payoutRequest.documentNumber}
                                    </p>
                                ) : null}
                                {payoutRequest?.documentType ? (
                                    <p>
                                        <strong>Document type:</strong>&nbsp;
                                        {payoutRequest.documentType}
                                    </p>
                                ) : null}
                                {payoutRequest?.region ? (
                                    <p>
                                        <strong>Region:</strong>&nbsp;
                                        {payoutRequest.region}
                                    </p>
                                ) : null}
                                {payoutRequest?.city ? (
                                    <p>
                                        <strong>City:</strong>&nbsp;
                                        {payoutRequest.city}
                                    </p>
                                ) : null}
                                {payoutRequest?.postalCode ? (
                                    <p>
                                        <strong>Postal Code:</strong>&nbsp;
                                        {payoutRequest.postalCode}
                                    </p>
                                ) : null}
                                <p>
                                    <strong>Recipient Email: </strong>&nbsp;
                                    {payoutRequest.recipientEmail ?? ''}
                                </p>
                                <p>
                                    <strong>Is the Payout Request Open: </strong>&nbsp;
                                    {payoutRequest.isOpen ? 'Yes' : 'No'}
                                </p>
                                <p>
                                    <strong>Is Mandate Accepted: </strong>&nbsp;
                                    {payoutRequest.isMandateAccepted ? 'Yes' : 'No'}
                                </p>
                            </>
                        ) : (
                            <>
                                <p>Payout request with this UUID ({id}) doesnt exist!</p>
                                <a href={routes.payoutRequestsRoute}>Go back to Payout Requests List</a>
                            </>
                        )}
                    </LoadingArea>
                </Stack>
            </Box>
            {(payoutRequest?.isOpen || nextRedirect) && payoutRequest?.amount && (
                <Box>
                    <h3>Actions</h3>
                    {showPaypalButton ? (
                        <Button
                            type="button"
                            onClick={createPaypalPayment}
                            fullWidth
                            color={theme.colors.knowunityBlue}
                            hoverColor={theme.colors.knowunityBlueDark}
                        >
                            Payout with PayPal and close this payout request
                        </Button>
                    ) : null}
                    {showCreateSevDeskVoucherButton ? (
                        <Button
                            type="button"
                            onClick={createPayoutVoucher}
                            fullWidth
                            color={theme.colors.knowunityBlue}
                            hoverColor={theme.colors.knowunityBlueDark}
                        >
                            Create payout Voucher on SevDesk
                        </Button>
                    ) : null}
                    <Button type="button" onClick={closePayoutRequest} fullWidth>
                        Mark Payout Request as done
                    </Button>
                    {payoutRequest?.isOpen ? (
                        <RefusalRow>
                            <SelectElement
                                label="Refusal reason"
                                noMargin
                                options={paymentRefusalReasons}
                                onUpdate={updatePaymentRefusalReason}
                            />

                            <Button
                                type="button"
                                onClick={refusePayoutRequest}
                                fullWidth
                                color="var(--red)"
                                hoverColor="var(--red-dark)"
                            >
                                Refuse Payout Request
                            </Button>
                        </RefusalRow>
                    ) : null}
                    <br />

                    {nextRedirect && (
                        <Button type="button" onClick={goToNextReviewKnow} fullWidth>
                            Move to the next Payout Request
                        </Button>
                    )}
                </Box>
            )}
            {payoutEverything ? (
                <Box>
                    <Button type="button" onClick={setRequestAmountToKnowerBalance} fullWidth>
                        Set payout amount to Knower Balance (Max payout amount)
                    </Button>
                </Box>
            ) : null}
            {payoutRequest?.uuid ? <FraudDetectionResult payoutRequestUuid={payoutRequest.uuid} /> : null}
            {payoutRequest?.filePath ? (
                <Box>
                    <Stack>
                        <h3>Invoice</h3>
                        <p>
                            You can download the invoice by using the PDF viewer below, by clicking on the download icon on
                            the right side of the viewer.
                        </p>
                        <p>
                            Copy the text below and use it for renaming the downloaded invoice:{' '}
                            <strong>
                                {`invoice_${payoutRequest.invoiceID} (${payoutRequest.countryCode}${payoutRequest.invoiceID})`}
                                <CopyIcon
                                    text={`invoice_${payoutRequest.invoiceID} (${payoutRequest.countryCode}${payoutRequest.invoiceID})`}
                                />
                            </strong>
                        </p>

                        <InvoiceViewer
                            ref={iframeRef}
                            id="viewer"
                            title="Invoice"
                            src={payoutRequest.filePath}
                            frameBorder="0"
                            height="1000px"
                            width="100%"
                            loading="lazy"
                            onLoad={() => setIframeLoaded(true)}
                        >
                            <p>Cannot display the Invoice</p>
                            <a href={payoutRequest.filePath} target="_blank" rel="noreferrer">
                                See the invoice here
                            </a>
                        </InvoiceViewer>
                        <a href={payoutRequest.filePath!} target="_blank" rel="noreferrer">
                            Alternative file path
                        </a>
                    </Stack>
                </Box>
            ) : null}
        </section>
    )
}

export default withRouter(PayoutRequest)
