import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import SubjectService from './SubjectService'
import { Subject } from 'interfaces/Subject'
import SubjectForm from './SubjectsForm'
import styled from 'styled-components'
import { getColorIndicator } from 'utils/subjects'
import { returnGradeLabel } from 'lib/features/grades'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'subjectContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SubjectComponent = (props: Props) => {
    const [subject, setSubject] = useState<Subject | null>(null)

    const id = props.match.params.id
    const isNew = !id || id === 'create'

    const updateSubject = (subject: Subject) => setSubject(subject)

    const fetchData = useCallback(() => {
        if (id && !isNew) {
            trackPromise(
                SubjectService.get(+id)
                    .then((subject) => {
                        updateSubject(subject)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting subject.')
                    }),
                loadingAreas.container
            )
        }
    }, [id, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="Subject" />
            <h2>Subject</h2>
            <GoBackButton route={routes.subjectsRoute} routeName="Subjects" />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {subject && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {subject.id}
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {subject.name}
                                    </p>
                                    <p>
                                        <strong>English name:</strong>&nbsp;
                                        {subject.englishName ?? 'Not specified'}
                                    </p>
                                    <p>
                                        <strong>Color:</strong>&nbsp;
                                        {getColorIndicator(subject.color)}
                                    </p>
                                    <p>
                                        <strong>Icon:</strong>&nbsp;
                                        <img src={subject.iconUrl} alt="Icon" />
                                    </p>
                                    <p>
                                        <strong>Country: </strong>&nbsp;
                                        {subject.country?.englishName}
                                    </p>
                                    {subject?.language ? (
                                        <p>
                                            <strong>Language: </strong>&nbsp;
                                            {`${subject.language?.englishName} (${subject.language.code})`}
                                        </p>
                                    ) : null}
                                    <p>
                                        <strong>Category:</strong>&nbsp;
                                        {subject.category.name}
                                    </p>
                                    <p>
                                        <strong>Sort Id:</strong>&nbsp;
                                        {subject.sortId}
                                    </p>
                                    {subject?.grades?.length ? (
                                        <p>
                                            <strong>Grades: </strong>&nbsp;
                                            {subject.grades.map((grade) => returnGradeLabel(grade)).join(', ')}
                                        </p>
                                    ) : null}
                                    {subject?.examType ? (
                                        <p>
                                            <strong>Exam Type: </strong>&nbsp;
                                            {subject.examType.name}
                                        </p>
                                    ) : null}
                                    {subject?.isHidden ? (
                                        <p>
                                            <strong>Is hidden: </strong>&nbsp;
                                            {subject.isHidden ? 'yes' : 'no'}
                                        </p>
                                    ) : null}
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            <SubjectForm subject={subject} isNew={isNew} updateSubject={updateSubject} />
        </Container>
    )
}

export default SubjectComponent
