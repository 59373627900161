import React, { useEffect, useRef, useState } from 'react'
import { AdCustomer } from 'interfaces/AdSystem'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { FormSelect, Input, SelectOptionWithLabel } from 'components/inputs/Input'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors, formatUTCDateForRequest } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Modal from 'components/modules/Modal'
import { AdAttachment, AdAttachmentRequest, AdAttachmentType, SurveyQuestion } from 'interfaces/AdAttachmentSet'
import AdAttachmentService from 'services/AdAttachmentService'
import AdAttachmentSurveyForm from './AdAttachmentSurveyForm'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'

const loadingAreas = {
    imageUpload: 'adsAssetFormImageUpload',
    save: 'adsAssetFormSave',
}

interface FormData {
    sortId: number
    type: SelectOptionWithLabel<AdAttachmentType>
    url: string
    name: string
    title: string
    startsOn: string
    endsOn: string
    description: string | null
    location: string | null
    applicationTitle: string
    surveyDescription: string
    typeformUrl: string
}

interface Props {
    show: boolean
    handleClose: () => void
    adCustomer: AdCustomer
    adAttachment?: AdAttachment
}

const AdAttachmentForm = ({ adCustomer, show, handleClose, adAttachment }: Props) => {
    const [initialData, setInitialData] = useState<Partial<FormData>>()
    const [applicationSurveyQuestions, setApplicationSurveyQuestions] = useState<SurveyQuestion[]>([])
    const [selectedAttachmentType, setSelectedAttachmentType] = useState<AdAttachmentType>(
        (adAttachment?.type as AdAttachmentType) ?? AdAttachmentType.Link
    )
    const formRef = useRef<FormHandles>(null)

    const onAttachmentFormClose = () => {
        handleClose()
        setApplicationSurveyQuestions([])
        setSelectedAttachmentType(AdAttachmentType.Link)
    }

    useEffect(() => {
        if (adAttachment) {
            const adAttachmentHasAnEvent = !!adAttachment?.events?.length
            const adAttachmentHasAdditionalQuestionSurvey = !!adAttachment?.applicationSurvey?.additionalQuestions?.length

            const initialData = {
                sortId: +adAttachment.sortId,
                type: adAttachment.type
                    ? ({
                          label: adAttachment.type,
                          identifier: adAttachment.type,
                          data: adAttachment.type,
                      } as SelectOptionWithLabel<AdAttachmentType>)
                    : undefined,
                url: adAttachment?.link?.url,
                name: adAttachment?.link?.name,
                title: adAttachmentHasAnEvent ? adAttachment?.events[0]?.title : undefined,
                startsOn: adAttachmentHasAnEvent ? adAttachment?.events[0]?.startsOn?.slice(0, -1) : undefined,
                endsOn: adAttachmentHasAnEvent ? adAttachment?.events[0]?.endsOn?.slice(0, -1) : undefined,
                description: adAttachmentHasAnEvent ? adAttachment?.events[0]?.description : undefined,
                location: adAttachmentHasAnEvent ? adAttachment?.events[0]?.location : undefined,
                applicationTitle: adAttachment?.applicationSurvey?.title,
                surveyDescription: adAttachment?.applicationSurvey?.description,
                typeformUrl: adAttachment?.applicationSurvey?.typeformUrl ?? undefined,
            }

            adAttachmentHasAdditionalQuestionSurvey &&
                setApplicationSurveyQuestions(adAttachment.applicationSurvey.additionalQuestions)

            setSelectedAttachmentType(adAttachment.type as AdAttachmentType)

            setInitialData(initialData)
        } else {
            setSelectedAttachmentType(AdAttachmentType.Link)
            setInitialData({
                type: {
                    label: AdAttachmentType.Link,
                    identifier: AdAttachmentType.Link,
                    data: AdAttachmentType.Link,
                } as SelectOptionWithLabel<AdAttachmentType>,
            })
        }
    }, [adAttachment])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})
        const {
            applicationTitle,
            surveyDescription,
            title,
            startsOn,
            endsOn,
            typeformUrl,
            description,
            location,
            type,
            url,
            name,
            sortId,
            ...rest
        } = data

        if (type?.data === AdAttachmentType.Survey && !applicationSurveyQuestions?.length) {
            toast.warning('Please add atleast 1 additional questions')
            return
        }

        if (type?.data === AdAttachmentType.Event) {
            if (!startsOn.length || isNaN(new Date(startsOn).getTime())) {
                formRef.current!.setFieldError('startsOn', 'Please specify the correct date')
                return
            }
            if (!endsOn.length && isNaN(new Date(endsOn).getTime())) {
                formRef.current!.setFieldError('endsOn', 'Please specify the correct date')
                return
            }
            if (startsOn && endsOn && new Date(startsOn).getTime() > new Date(endsOn).getTime()) {
                formRef.current!.setFieldError('endsOn', 'The end date must be after the start date.')
                return
            }
        }

        const request: AdAttachmentRequest = {
            type: type?.data,
            link:
                name && url
                    ? {
                          name,
                          url,
                      }
                    : undefined,
            event:
                title && startsOn && endsOn
                    ? {
                          title,
                          startsOn: formatUTCDateForRequest(startsOn),
                          endsOn: formatUTCDateForRequest(endsOn),
                          description,
                          location,
                      }
                    : undefined,
            applicationSurvey:
                applicationTitle && applicationSurveyQuestions
                    ? {
                          title: applicationTitle,
                          additionalQuestions: applicationSurveyQuestions,
                          description: surveyDescription,
                          typeformUrl: typeformUrl ?? null,
                      }
                    : undefined,

            sortId: +sortId,
            customerUuid: adCustomer.uuid,
            ...rest,
        }

        let handleFunc
        if (!adAttachment) {
            handleFunc = AdAttachmentService.createSet(request)
        } else {
            handleFunc = AdAttachmentService.updateSet(adAttachment.uuid, request)
        }

        trackPromise(
            handleFunc
                .then(() => {
                    toast.success('Attachment has been saved.')
                    onAttachmentFormClose()
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown(`An error occurred while updating an attachment.`)
                    }
                }),
            loadingAreas.save
        )
    }

    const attachmentTypeOptions = Object.keys(AdAttachmentType).map((typeKey) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: AdAttachmentType[typeKey],
        // eslint-disable-next-line
        // @ts-ignore
        identifier: AdAttachmentType[typeKey],
        // eslint-disable-next-line
        // @ts-ignore
        data: AdAttachmentType[typeKey],
    }))

    const onTypeSelected = (type: AdAttachmentType) => setSelectedAttachmentType(type)

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload a PDF!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (innerFile.type !== 'application/pdf') {
            toast.error('Please upload your file as a PDF!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.Content)
                .then(({ url }) => {
                    formRef.current?.setFieldValue('url', url)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.imageUpload
        )
    }

    const returnFormElementBasedOnAttachmentType = () => {
        switch (selectedAttachmentType) {
            case AdAttachmentType.Link:
                return (
                    <>
                        <UploadWrapper
                            handleFileUpload={onChangeFile}
                            area={loadingAreas.imageUpload}
                            acceptRules="application/pdf"
                        >
                            <p>Click here to select a PDF to be uploaded as the URL.</p>
                        </UploadWrapper>
                        <Input name="url" placeholder="URL (link to website or link to a PDF)" />
                        <Input name="name" placeholder="Name" />
                    </>
                )
            case AdAttachmentType.Event:
                return (
                    <>
                        <Input name="title" placeholder="Title" />
                        <Input name="startsOn" placeholder="Start date (UTC time)" type="datetime-local" />
                        <Input name="endsOn" placeholder="End date (UTC time)" type="datetime-local" />
                        <Input name="description" placeholder="Description (optional)" />
                        <Input name="location" placeholder="Location (optional)" />
                    </>
                )
            case AdAttachmentType.Survey:
                return (
                    <>
                        <Input name="applicationTitle" placeholder="Title" />
                        <Input name="surveyDescription" placeholder="Description (optional)" />
                        <Input name="typeformUrl" placeholder="Typeform URL (optional)" />
                        <AdAttachmentSurveyForm
                            applicationSurveyQuestions={applicationSurveyQuestions}
                            setApplicationSurveyQuestions={setApplicationSurveyQuestions}
                        />
                    </>
                )
        }
    }

    return (
        <Modal show={show} title={`${adAttachment ? 'Update' : 'Create'} Ad Attachment`} onClose={onAttachmentFormClose}>
            <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
                {adAttachment ? (
                    <Body1 color={theme.colors.white}>Event Type: {adAttachment.type}</Body1>
                ) : (
                    <>
                        {attachmentTypeOptions && (
                            <FormSelect
                                name="type"
                                options={attachmentTypeOptions}
                                placeholder="Type"
                                onUpdate={onTypeSelected}
                            />
                        )}
                    </>
                )}
                {returnFormElementBasedOnAttachmentType()}
                <Input name="sortId" type="number" placeholder="Sort id" />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {adAttachment ? 'Save' : 'Update'}
                </Button>
            </Form>
        </Modal>
    )
}

export default AdAttachmentForm
