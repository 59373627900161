import { PayoutRequest } from 'interfaces/PayoutRequest'
import { PayoutBulkData } from 'pages/PayoutRequests/PayoutRequests'
import { formatDate } from 'utils'

export const returnPayoutStatus = (payout: PayoutRequest) =>
    payout.isOpen ? 'Open' : `${payout.status === 'REFUSED' ? 'Refused' : 'Closed'} on: ${formatDate(payout.closedOn!)}`

export const getPayoutCSVHeaders = (useSortCode?: boolean) => {
    let headers = [
        'name',
        'recipientEmail',
        'paymentReference',
        'receiverType',
        'amountCurrency',
        'amount',
        'sourceCurrency',
        'targetCurrency',
    ]
    if (useSortCode) {
        headers = [...headers, 'sortCode', 'accountNumber']
    } else {
        headers = [...headers, 'IBAN']
    }
    return headers
}

export const generateCSVRowFromPayout = (payout: PayoutBulkData, useSortCode?: boolean) =>
    `${payout.name},${payout.recipientEmail},${payout.invoiceId},PERSON,target,${(payout.amount / 100).toFixed(2)},EUR,${
        payout.currency
    },${useSortCode ? `${payout.sortCode},${payout.accountNumber}` : payout.iban}\n`

export const sevDeskPayoutUrl = (sevDeskVoucherId: string) => `https://my.sevdesk.de/#/ex/detail/id/${sevDeskVoucherId}`
