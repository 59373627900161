export enum FeedbackInputType {
    Answer = 'ANSWERS',
    Rating = 'RATING',
    Phone_Number = 'PHONE_NUMBER',
    Text = 'TEXT',
    Link = 'LINK',
    UserResearchPhoneNumber = 'USER_RESEARCH_PHONE_NUMBER',
}

export interface FeedbackDialog {
    uuid: string
    actionLink: string
    actionsUntilTriggered: number
    identifier: string
    isActive: boolean
    isMultipleChoice: boolean
    title: string
    userTargetingId: number
    percentage: number
    inputType: FeedbackInputType
    answerOptions: {
        answerText: string
        actionLink: string | null
    }[]
}

export interface FeedbackDialogAnswerOptionRequest {
    answerText: string
    actionLink: string | null
}

export interface FeedbackDialogCreateRequest {
    identifier: string
    isActive: boolean
    title: string
    userTargetingId: number
    actionLink: string | null
    actionsUntilTriggered: number
    answerOptions: FeedbackDialogAnswerOptionRequest[]
    percentage: number
    inputType: string
    isMultipleChoice: boolean
}
