import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import FeedbackDialogService from './FeedbackDialogService'
import { FeedbackDialog } from 'interfaces/FeedbackDialog'
import FeedbackDialogForm from './FeedbackDialogForm'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'
import { feedbackDialogResult } from 'lib/constants/urls'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'knowDemandContainer',
}

const FeedbackDialogComponent = (props: Props) => {
    const [feedbackDialog, setFeedbackDialog] = useState<FeedbackDialog | null>(null)
    const uuid = props.match.params.uuid
    const isNew = !uuid

    const updateFeedbackDialog = (feedback: FeedbackDialog) => setFeedbackDialog(feedback)

    const fetchData = useCallback(() => {
        if (uuid) {
            trackPromise(
                FeedbackDialogService.get(uuid)
                    .then((feedback) => {
                        setFeedbackDialog(feedback)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting know demand.')
                    }),
                loadingAreas.container
            )
        }
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="Feedback dialog" />

            <h2>Feedback dialog</h2>
            <GoBackButton route={routes.feedbackDialogsRoute} routeName="Feedback Dialogs" />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {feedbackDialog && (
                                <>
                                    <p>
                                        <strong>Title:</strong>&nbsp;
                                        {feedbackDialog.title}
                                    </p>
                                    <p>
                                        <strong>Input type:</strong>&nbsp;
                                        {feedbackDialog.inputType}
                                    </p>
                                    <p>
                                        <strong>Uuid:</strong>&nbsp;
                                        {feedbackDialog.uuid}
                                        <CopyIcon text={feedbackDialog.uuid} />
                                    </p>
                                    <p>
                                        <strong>Answer count:</strong>&nbsp;
                                        {feedbackDialog?.answerOptions?.length ?? 0}
                                    </p>
                                    <p>
                                        <strong>Is active:</strong>
                                        &nbsp;
                                        {feedbackDialog.isActive ? 'Yes' : 'No'}
                                    </p>
                                    <p>
                                        <strong>Is multiple choice:</strong>
                                        &nbsp;
                                        {feedbackDialog.isMultipleChoice ? 'Yes' : 'No'}
                                    </p>
                                    <p>
                                        <strong>Feedback results:</strong>
                                        &nbsp;
                                        <a href={feedbackDialogResult(feedbackDialog.uuid)} target="_blank" rel="noreferrer">
                                            link
                                        </a>
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            <FeedbackDialogForm
                dialogUuid={uuid}
                isNew={isNew}
                feedbackDialog={feedbackDialog}
                updateFeedbackDialog={updateFeedbackDialog}
            />
        </section>
    )
}

export default FeedbackDialogComponent
